import React, { useMemo } from "react";

import { CarStatusBadge, Pill } from "@/components";
import { cn, formatNumber } from "@/utils";
import {CAR_DISPLAY_STATUS} from '@/const'

import { CarInfoList, FuelTypesList } from "./CarCardsComponents";

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarGroupSchema } props.carGroup - Car group data
 * @param {Boolean} [props.showMake=false] - Show make of the car
 * @param {Boolean} [props.rental=false] - Show min rental price of the car. If false, show min purchase price
 * @param {string} [props.className] - Additional classes to apply
 */
export const CarGroupCard = ({
  carGroup,
  showMake = false,
  rental = false,
  className,
  ...props
}) => {
  const href = useMemo(() => {
    const url = new URL(`/nyir-bilar/${carGroup.make}/${carGroup.model}`, window.location.origin);

    if (rental) {
      url.searchParams.append("type", "rental");
    }

    return url.href;
  }, [carGroup.make, carGroup.model, rental]);


  const {energyGrantDiscount, offerDiscount, minPrice} = useMemo(() => {
    if (rental) {
      return { energyGrantDiscount: null, offerDiscount: null, minPrice: carGroup.pricing.rental.min };
    }
    let energyGrant = null;
    let offer = null;
    let price = null;
    for (var discount of carGroup.discounts) {
      if (discount.code === "energy_grant") {
        energyGrant = discount;
      } else if (discount.code === "offer_price") {
        offer = discount;
      }
    }
    price = carGroup.pricing.purchase.min;
    if (offer) {
      price = price - offer.amount;
    }
    if (energyGrant) {
      price = price - energyGrant.amount;
    }
    return { energyGrantDiscount: energyGrant, offerDiscount: offer, minPrice: price };
  },[carGroup,rental]);
  

  return (
    <div className={cn("card car-card", className)} {...props}>
      <a href={href} className="car-card__link">
        <div className="car-card__badge-container">
          <div className="badge-wrapper d-flex gap-3 ms-auto">
            {carGroup.display_statuses.map((status) => (
              <CarStatusBadge key={status} status={status} />
            ))}
            {carGroup.kjarabill && <CarStatusBadge status={CAR_DISPLAY_STATUS.KJARABILL} />}
            {energyGrantDiscount && <Pill label="Orkusjóðsstyrkur" color="green" />}
            {offerDiscount && <CarStatusBadge status={CAR_DISPLAY_STATUS.MODEL_ON_OFFER} />}
          </div>
        </div>
        <img
          src={carGroup.image}
          className="car-card__img"
          alt={`${carGroup.make} ${carGroup.model}`}
        />
        <p className="car-card__title">{`${showMake ? carGroup.make : ""} ${carGroup.model}`}</p>
        <FuelTypesList fuelTypes={carGroup.fuelTypes ?? []} />
        <div className="car-card__info-container">
          <div className="car-card__price-container">
            {energyGrantDiscount && <p className="car-card__price-label">Verð frá með styrk</p>}
            {!energyGrantDiscount && <p className="car-card__price-label">Verð frá</p>}
            <p className="car-card__price">{formatNumber(minPrice)} kr.</p>
          </div>
          <CarInfoList
            batteryCapacity={carGroup.battery_capacity}
            range={carGroup.range}
            fuelEconomy={carGroup.fuel_economy}
          />
        </div>
      </a>
    </div>
  );
};
